export const authInitialState = {
  mobileNumber: "",
  otp: "",
  otpVerifier: null,
  birthday: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: {
      value: "",
    },
  },
  otpShowResendMessage: false,
  otpResendCount: 1,
  incorrectBirthdayAttempt: false,
  lastName: "",
  oldMobileNumber: "",
  newMobileNumber: "",
  idType: {
    label: "",
    value: "",
  },
}
