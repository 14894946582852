export const initialState = {
  agreeToMechanics: [],
};

const forms = {
  SAVE_DOCTOR_ENROLLMENT: "SAVE_DOCTOR_ENROLLMENT"
};

export const DoctorEnrollmentReducer = (state, action) => {
  switch( action.type ){
    case forms.SAVE_DOCTOR_ENROLLMENT:
      return { ...state, ...action.payload };
    default:
      return { ...state, ...state.initialState };
  }
}