import React, { useReducer } from "react"

import { PatientEnrollmentReducer } from "./PatientEnrollmentReducer"
import { useAirtablePatientEnrollmentFormFields } from "./hooks/useAirtablePatientEnrollmentFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const PatientEnrollmentContext = React.createContext()

const PatientEnrollmentProvider = ({ children }) => {
  let formFields = useAirtablePatientEnrollmentFormFields()
  formFields = flattenNode(
    formFields?.allAirtableallPatientEnrollmentFormFields
  )
  let [patientEnrollmentState, patientEnrollmentDispatch] = useReducer(
    PatientEnrollmentReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      initialState: {
        ...generateInitialValues({ fields: formFields }),
        documents: [],
      },
    }
  )

  return (
    <PatientEnrollmentContext.Provider
      value={{ patientEnrollmentState, patientEnrollmentDispatch }}
    >
      {children}
    </PatientEnrollmentContext.Provider>
  )
}

export { PatientEnrollmentContext, PatientEnrollmentProvider }
