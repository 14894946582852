import React, { useReducer } from "react"

import { EpharmacyReducer } from "./EpharmacyReducer"
import { generateInitialValues } from "../../../services/context"
import { useAirtableEpharmacyFormFields } from "./hooks/useAirtableEpharmacyFormFields"

let { flattenNode } = require("../../../services/arrays")

const EpharmacyContext = React.createContext()

const EpharmacyProvider = ({ children }) => {
  let formFields = useAirtableEpharmacyFormFields()
  formFields = flattenNode(formFields?.allAirtableallEpharmacyFormFields)

  let [epharmacyState, epharmacyDispatch] = useReducer(EpharmacyReducer, {
    documents: [],
    medicines: [],
    consentAndAuthorization: [],
    pillSplitAgreement: [],
    otherHospital: "",
    overrideAddress: [],
    ...generateInitialValues({ fields: formFields }),
    initialState: {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      medicines: [],
      consentAndAuthorization: [],
      pillSplitAgreement: [],
      otherHospital: "",
      overrideAddress: [],
      ...generateInitialValues({ fields: formFields }),
    },
  })

  return (
    <EpharmacyContext.Provider value={{ epharmacyState, epharmacyDispatch }}>
      {children}
    </EpharmacyContext.Provider>
  )
}

export { EpharmacyContext, EpharmacyProvider }
