export const isObjectEmpty = (object) => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const formatString = (object) => {
  if (!object) return ""
  if (typeof object === "object" && object["year"]) return buildDate(object)
  else if (typeof object === "string") return object
  else if (typeof object === "number") return object.toString()
  else if (typeof object === "object") {
    try {
      let data = []
      if (Object.keys(object).length === 0 && object.length === 0) return "N/A"
      object.forEach((item) => {
        if (item.value) data.push(item.value)
        else data.push(item)
      })
      data.sort()
      return data.join(", ")
    } catch (exception) {
      return ""
    }
  } else return "N/A"
}

export const buildDate = ({ year, month, date }) => {
  if (!year) return "N/A"
  const buildMonth = month?.value ? `${month?.value} ` : ""
  const buildDate = date?.value ? `${("0" + date?.value).slice(-2)} ` : ""
  return `${buildMonth}${buildDate}${year}`
}
