import formFields from "./formFields.json"
import patientEnrollment from "./patientEnrollment.json"

import homeBanner from "./homeBanner.json"

import cta from "./cta.json"
import epharmacyUpload from "./epharmacyUpload.json"
import uploadPrescriptionGuidelines from "./uploadPrescriptionGuidelines.json"
import epharmacyMedicines from "./epharmacyMedicines.json"
import epharmacyCheckout from "./epharmacyCheckout.json"
import epharmacyPayment from "./epharmacyPayment.json"

import epharmacySummary from "./epharmacySummary.json"

import home from "./home.json"

export const tl = () => {
  let translation = {
    ...home,
    ...formFields,
    ...cta,
    ...patientEnrollment,
    ...homeBanner,
    ...epharmacyUpload,
    ...epharmacyMedicines,
    ...uploadPrescriptionGuidelines,
    ...epharmacyCheckout,
    ...epharmacyPayment,
    ...epharmacySummary,
  }

  return {
    translation: {
      ...home,
      ...formFields,
      ...cta,
      ...patientEnrollment,
      ...homeBanner,
      ...epharmacyUpload,
      ...epharmacyMedicines,
      ...uploadPrescriptionGuidelines,
      ...epharmacyCheckout,
      ...epharmacyPayment,
      ...epharmacySummary,
    },
  }
}
