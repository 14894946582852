export const initialState = {
  agreeToMechanics: [],
  documents: [],
}

const forms = {
  SAVE_PATIENT_ENROLLMENT: "SAVE_PATIENT_ENROLLMENT",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
}

export const PatientEnrollmentReducer = (state, action) => {
  switch (action.type) {
    case upload.SAVE_DOCUMENTS:
      return { ...state, documents: [...action.payload] }
    case forms.SAVE_PATIENT_ENROLLMENT:
      return { ...state, ...action.payload }
    default:
      return { ...state, ...state.initialState }
  }
}
