import React, { createContext, useEffect, useState } from "react"

export const PhoneAuthenticationContext = createContext()

const PhoneAuthenticationProvider = (props) => {
  const [uid, setUid] = useState("")

  const setUser = (user) => {
    sessionStorage.setItem("uid", user.uid)
    setUid(user.uid)
  }
  const getUser = () => {
    const getUID = sessionStorage.getItem("uid")
    setUid(getUID)
  }
  const removeUser = () => {
    sessionStorage.clear("uid")
    setUid("")
  }
  useEffect(() => {
    getUser()
  }, [])
  return (
    // passing names to provider for get in components as a props
    <PhoneAuthenticationContext.Provider value={{ setUser, removeUser, uid }}>
      {/* components */}
      {props.children}
    </PhoneAuthenticationContext.Provider>
  )
}

export default PhoneAuthenticationProvider
